.see-you-soon {
    font-size: 6rem; 
    font-style: italic; 
    text-align: center; 
    color: #4B5320; 
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5); /* Sombra de texto para destacar */
    letter-spacing: 3px; /* Espaçamento entre as letras */
    margin: 0;
    padding: 20px;
  }
  
  @media (max-width: 767px) {
    .see-you-soon {
      font-size: 4rem; /* Tamanho menor para dispositivos móveis */
    }
  }
  
